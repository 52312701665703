import React, { useEffect, useState } from "react";
import AritstPageHeader from "../../components/AritstPageHeader";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import "../../assets/css/images.css";
import "../../assets/css/artist-masnary.css";
import {
	Masnory1,
	Masnory10,
	Masnory11,
	Masnory12,
	Masnory2,
	Masnory3,
	Masnory4,
	Masnory5,
	Masnory6,
	Masnory7,
	Masnory8,
	Masnory9,
} from "../../constant/index";
import { useAtistResourcesQuery } from "../../store/services/ArtistCornerServices/ArtistBlogService";
import Pagination from "../../components/Pagination";
import Loader from "../../components/Loader";

const ArtistMasnory = () => {
	const [currentpage, setCurrentpage] = useState(1);
	const { data, isLoading } = useAtistResourcesQuery(currentpage);
	const Resourses = data?.data;

	// Pagination
	const pages = Math.ceil(Resourses?.total_size / Resourses?.limit);
	const handlePageClick = async (data) => {
		setCurrentpage(data?.selected + 1);
	};
	return isLoading ? (
		<Loader />
	) : (
		<>
			<Header />
			{/* Artist-header starts here */}
			<AritstPageHeader />
			{/* Artist-header starts here */}

			{/* Blog Sec Start Here */}
			<section className="artist-masnary">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="mas-heading-wrapper">
								<h2>Resources</h2>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="masonry_box">
								<ResponsiveMasonry
									columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1100: 4 }}
								>
									<Masonry columnsCount={4} gutter="10px">
										{Resourses?.resources?.map((image, i) => (
											<a href={`/artist-recourses?slug=${image?.slug}`} key={i}>
												<div className="image_box">
													<figure>
														<img
															alt={image?.title}
															key={i}
															src={image?.section_1_image_url}
															style={{
																width: "317px",
																height: "237px",
																display: "block",
															}}
														/>
													</figure>
													<div className="bottom-bar">
														<h3 className="resources_clamp">{image?.title}</h3>
														<p>{image?.section_1_short_description}</p>
													</div>
												</div>
											</a>
										))}
									</Masonry>
								</ResponsiveMasonry>
								<div className="paginations-wrapper">
									<Pagination pages={pages} handlePageClick={handlePageClick} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Blog Sec End Here */}
			<Footer />
		</>
	);
};

export default ArtistMasnory;
