import React, { useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/contributors.css";
import {
	aboutpageimg,
	community,
	create,
	earning,
	moneyimg,
	sell,
	upload,
	backArrow,
} from "../../constant";
import { useContributorQuery } from "../../store/services/ContactServices";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Backhistory from "../../components/Backhistory";
const Contributors = () => {
	const { contrubutorToken } = useSelector((state) => state.AuthReducer);
	const { data, isLoading } = useContributorQuery();
	const Contributor = data?.data?.contributor;
	const Image = data?.data?.images;
	const Video = data?.data?.videos;
	const faqs = data?.data?.faqs;

	console.log(Contributor, "Asdklasde3");

	let navigate = useNavigate();
	return isLoading ? (
		<Loader />
	) : (
		<>
			<Header />
			{/* Contributors page header starts here */}
			<section className="about-page-header">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="back_gif_main d-flex justify-content-between align-items-center">
								<div className="about-page-header-content-wrapper">
									<h1>Contributors</h1>
								</div>
								<Backhistory classNa={"back_icon"} />
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Contributors page header  ends here */}
			{/* what is contributors starts here */}
			<section className="what-contributors">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="cont-img-wrapper">
								<figure>
									<img
										src={Contributor?.section_1_sub_image_url}
										className="img-fluid"
										alt="Contributor Image"
									/>
								</figure>
								<figure>
									<img
										src={Contributor?.section_1_sub_image_2_url}
										className="img-fluid"
										alt="Contributor Image"
									/>
								</figure>
								<figure>
									<img
										src={Contributor?.section_1_sub_image_3_url}
										className="img-fluid"
										alt="Contributor Image"
									/>
								</figure>
								<figure>
									<img
										src={Contributor?.section_1_sub_image_4_url}
										className="img-fluid"
										alt="Contributor Image"
									/>
								</figure>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="contributors-content-wrapper">
								<h2>{Contributor?.section_1_title}</h2>
								<p
									dangerouslySetInnerHTML={{
										__html: Contributor?.section_1_details,
									}}
								></p>
								<div className="con-icons-wrapper">
									<ul>
										<li>
											<div className="icons-wrapper">
												<div className="icon">
													<img src={create} className="img-fluid" alt="" />
												</div>
												<div className="icon-content-wrapper">
													<h5>{Contributor?.section_1_sub_title}</h5>
													<p
														dangerouslySetInnerHTML={{
															__html: Contributor?.section_1_sub_details,
														}}
													></p>
												</div>
											</div>
										</li>
										<li>
											<div className="icons-wrapper">
												<div className="icon">
													<img src={upload} className="img-fluid" alt="" />
												</div>
												<div className="icon-content-wrapper">
													<h5>{Contributor?.section_1_sub_title_2}</h5>
													<p
														dangerouslySetInnerHTML={{
															__html: Contributor?.section_1_sub_details_2,
														}}
													></p>
												</div>
											</div>
										</li>
										<li>
											<div className="icons-wrapper">
												<div className="icon">
													<img src={sell} className="img-fluid" alt="" />
												</div>
												<div className="icon-content-wrapper">
													<h5>{Contributor?.section_1_sub_title_3}</h5>
													<p
														dangerouslySetInnerHTML={{
															__html: Contributor?.section_1_sub_details_3,
														}}
													></p>
												</div>
											</div>
										</li>
										{!contrubutorToken && (
											<li>
												<div className="btn-wrapper">
													<button
														onClick={() => navigate("/register-contributor")}
														className="btn"
														disabled={contrubutorToken ? true : false}
													>
														Become A Contributor
													</button>
												</div>
											</li>
										)}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* what is contributors ends here */}
			{/* Why contributors starts here  */}
			<section className="why-contribute">
				<div className="container">
					<div className="row">
						<div className="col-lg-2"></div>
						<div className="col-lg-8 text-center">
							<div className="why-contribute-heading-wrapper">
								<h3>{Contributor?.title_2}</h3>
								<p
									dangerouslySetInnerHTML={{
										__html: Contributor?.details_2,
									}}
								></p>
							</div>
						</div>
						<div className="col-lg-2"></div>
					</div>
					<div className="row mt-md-5">
						<div className="col-lg-4">
							<div className="why-card-wrapper">
								<div className="why-img-wrapper">
									{/* <i className="fa-regular fa-pen-to-square"></i> */}
									<img src={community} className="img-fluid" alt="" />
								</div>
								<div className="why-content-wrapper">
									<h5>{Contributor?.section_2_title_1}</h5>
									<p
										dangerouslySetInnerHTML={{
											__html: Contributor?.section_2_details_1,
										}}
									></p>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="why-card-wrapper">
								<div className="why-img-wrapper">
									<i className="fa-sharp fa-solid fa-images"></i>
								</div>
								<div className="why-content-wrapper ">
									<h5>{Contributor?.section_2_title_2}</h5>
									<p
										dangerouslySetInnerHTML={{
											__html: Contributor?.section_2_details_2,
										}}
									></p>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="why-card-wrapper">
								<div className="why-img-wrapper">
									<img src={earning} className="img-fluid" alt="" />
								</div>
								<div className="why-content-wrapper">
									<h5>{Contributor?.section_2_title_3}</h5>
									<p
										dangerouslySetInnerHTML={{
											__html: Contributor?.section_2_details_3,
										}}
									></p>
								</div>
							</div>
						</div>
					</div>
					{!contrubutorToken && (
						<div className="row mt-md-5">
							<div className="col-lg-12">
								<div className="btn-wrapper text-center">
									<button
										onClick={() => navigate("/register-contributor")}
										className="btn"
										disabled={contrubutorToken ? true : false}
									>
										Let’s Get Started
									</button>
								</div>
							</div>
						</div>
					)}
				</div>
			</section>
			{/* Why contributors ends here  */}
			{/* Become contributor  starts here */}
			<section className="become-con">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="become-heading-wrapper">
								<h3>{Contributor?.title_3}</h3>
							</div>
						</div>
					</div>
					<div className="row mt-4">
						<div className="col-lg-12">
							<div className="steps-wrapper">
								<ul>
									<li>
										<div className="step-card-wrapper">
											<div className="step-card-img-wrapper">
												{/* <h3>
												1<sup>st</sup>
											</h3> */}
												<h3>1</h3>
											</div>
											<div className="step-content-wrapper">
												<h4>{Contributor?.section_3_title_1}</h4>
												<p
													dangerouslySetInnerHTML={{
														__html: Contributor?.section_3_details_1,
													}}
												></p>
											</div>
										</div>
									</li>
									<li>
										<div className="step-card-wrapper">
											<div className="step-card-img-wrapper">
												<h3>2</h3>
											</div>
											<div className="step-content-wrapper">
												<h4>{Contributor?.section_3_title_2}</h4>
												<p
													dangerouslySetInnerHTML={{
														__html: Contributor?.section_3_details_2,
													}}
												></p>
											</div>
										</div>
									</li>
									<li>
										<div className="step-card-wrapper">
											<div className="step-card-img-wrapper">
												<h3>3</h3>
											</div>
											<div className="step-content-wrapper">
												<h4>{Contributor?.section_3_title_3}</h4>
												<p
													dangerouslySetInnerHTML={{
														__html: Contributor?.section_3_details_3,
													}}
												></p>
											</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Become contributor  ends here */}
			{/* our vision starts here */}
			<section className="our-vision con-vision">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="mission-content-wrapper">
								<h2>{Contributor?.section_4_title}</h2>
								<p
									dangerouslySetInnerHTML={{
										__html: Contributor?.section_4_details,
									}}
								></p>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-img-wrapper border-line-right">
								<figure>
									<img
										src={Contributor?.section_4_image_url}
										className="img-fluid w-100"
										alt="Contributor Image"
									/>
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* our vision ends here */}
			{/* Pricing starts here */}
			<section className="pricing-sec">
				<div className="container">
					<div className="image_pricing">
						<div className="row">
							<div className="col-lg-12">
								<div className="pricing-heading-wrapper">
									<h3>Images Pricing Structure</h3>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-12">
								<div className="pricing-table-wrapper">
									<div className="table-responsive">
										<table className="table">
											<thead>
												<tr>
													<th className="img-empty-heading">
														<span className="empty"></span>
													</th>
													<th className="Contributor-heading">
														<h5>Contributor Level</h5>
													</th>
													<th className="non-exclusive-heading">
														<h5>Non-Exclusive Rate</h5>
													</th>
													<th className="exclusive-heading">
														<h5>Exclusive Rate</h5>
													</th>
												</tr>
											</thead>
											<tbody>
												{Image?.map((item, index) => {
													return (
														<tr key={index}>
															{index === 0 ? (
																<td className="img-heading">
																	<h6>Images</h6>
																</td>
															) : (
																<td className="img-heading">
																	<span className="empty">
																		{/* <h6>Videos</h6> */}
																	</span>
																</td>
															)}

															<td className="level">
																<span>{item?.contributor_level}</span>
															</td>
															<td className="level">
																<span>{item?.non_exclusive_rate}%</span>
															</td>
															<td className="level">
																<span>{item?.exclusive_rate}%</span>
															</td>
														</tr>
													);
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="video_pricing">
						<div className="row">
							<div className="col-lg-12">
								<div className="pricing-heading-wrapper">
									<h3>Video Pricing Structure</h3>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-12">
								<div className="pricing-table-wrapper">
									<div className="table-responsive">
										<table className="table">
											<thead>
												<tr>
													<th className="img-empty-heading">
														<span className="empty"></span>
													</th>
													<th className="Contributor-heading">
														<h5>Contributor Level</h5>
													</th>
													<th className="non-exclusive-heading">
														<h5>Non-Exclusive Rate</h5>
													</th>
													<th className="exclusive-heading">
														<h5>Exclusive Rate</h5>
													</th>
												</tr>
											</thead>
											<tbody>
												{Video?.map((item, index) => {
													return (
														<tr key={index}>
															<td className="img-heading">
																<span className="empty">
																	<h6>Videos</h6>
																</span>
															</td>

															<td className="level">
																<span>{item?.contributor_level}</span>
															</td>
															<td className="level">
																<span>{item?.non_exclusive_rate}%</span>
															</td>
															<td className="level">
																<span>{item?.exclusive_rate}%</span>
															</td>
														</tr>
													);
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-8">
								<div className="pricing-below-content-wrapper">
									<p
										dangerouslySetInnerHTML={{
											__html: Contributor?.section_5_details,
										}}
									></p>
									<div className="btn-wrapper">
										<a href="/agreement" className="btn">
											Contributor Agreement
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Pricing starts here */}
			{/* Faq starts here */}
			<section className="faq faq-con">
				<div className="container">
					<div className="row heading-row">
						<div className="col-lg-12 text-center">
							<div className="faq-heading-wrapper">
								<h3>Contributor Faq</h3>
							</div>
						</div>
					</div>
					<div className="row mt-md-5">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<div className="faq-wrapper">
								<div className="accordion" id="accordionExample">
									{faqs?.map((item, index) => {
										return (
											<div className="accordion-item" key={index + 1}>
												<h2
													className="accordion-header"
													id={`heading${index + 1}`}
												>
													<button
														className="accordion-button"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target={`#collapse${index + 1}`}
														aria-expanded="true"
														aria-controls={`collapse${index + 1}`}
													>
														{item?.question}
													</button>
												</h2>
												<div
													id={`collapse${index + 1}`}
													className={`${
														index === 0
															? "accordion-collapse collapse show"
															: "accordion-collapse collapse"
													}`}
													aria-labelledby={`heading${index + 1}`}
													data-bs-parent="#accordionExample"
												>
													<div className="accordion-body">{item?.answer}</div>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
						<div className="col-lg-2"></div>
					</div>
				</div>
			</section>
			{/* Faq ends here */}
			{/* Promotion sec starts here */}
			<section className="join contributor-join">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="promotion-card-1-wrapper">
								<div className="promotion-card-1-content-wrapper">
									<p>{Contributor?.section_6_title}</p>
									<p
										dangerouslySetInnerHTML={{
											__html: Contributor?.section_6_description,
										}}
									></p>
									<button
										onClick={() => navigate("/register-contributor")}
										className="btn"
										disabled={contrubutorToken ? true : false}
									>
										Sign Me Up!
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Promotion sec ends here */}
			<Footer />
		</>
	);
};

export default Contributors;
