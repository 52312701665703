export const BaseUrl =
	"https://admin-moonscape.developer-ourbase-camp.com/api/v1/";
export const BaseUrlTwo =
	"https://admin-moonscape.developer-ourbase-camp.com/api/v3/";

export const authRegisters = "auth/register";
export const contributorRegisters = "contributor/registration";

export const authLogins = "auth/login";
export const authStatus = "auth/verify-email";
export const contribStatus = "contributor/auth/verify-email";
export const Generaltips = "general-tips";
export const userFavourite = "user/favourite-products";
export const userCreateFavourite = "user/products/mark-favourite";
export const contributorLogins = "contributor/auth/login";
export const contributorForget = "contributor/auth/forgot-password";
export const contributorResetPassword = "contributor/auth/reset-password";

export const veriyfyEmail = "auth/verify-email";
export const forgetPassword = "auth/forgot-password";
export const resetPassword = "auth/reset-password";
export const contactUs = "contacts/store";
export const newsLetter = "newsletter/subscribe";
export const homePage = "home";
export const employeesData = "employees";
export const aboutPage = "about-us";
export const pricePackage = "packages/index";
export const userDetails = "user/profile";
export const userUpdate = "user/update-profile";
export const userPassword = "user/update-password";
export const faqs = "faq";
export const testimonials = "testimonials";
export const copyright = "pages/copyright";
export const terms_condition = "pages/terms_condition";
export const agrement_terms = "website-terms";
export const privacy_policy = "pages/privacy_policy";
export const agreement = "pages/contribute";
export const contributor = "contributor";
export const videoSection = "products";
export const detailPage = "products/details";
export const reviewsPage = "products/reviews/submit";
export const detailVariantssection = "products/secondary-details";
export const subscriptionCreate = "subscription/create";
export const subscriptionCurrent = "subscription/current-subscription";
export const updateSubcription = "subscription/upgrade";
export const renewSubcription = "subscription/renew";
export const imageFilterApi = "products/filters";
export const PlaceorderApi = "user/order/place";
export const ThankyouApi = "paypal/update-status";
export const Addaddress = "user/address/add";
export const Getaddress = "user/address/list";
export const placeOrderapithanks = "user/order/thank-you";
export const downloadDatas = "user/download-history";
export const deleteFavourite = "user/products/remove-favourite";
export const autherProducts = "products/author";
export const Products_review = "products/reviews";
export const Search_Products = "products/search";
export const help_help = "how-can-we-help";
export const coupon_code = "coupon/apply";

// Artist Corner API EndPoint
export const ArtistCornerEndPoint = "contributor/artists-corner";
export const main_blog = "mainblogs";
export const ArtistBlogEndPoint = "blogs";
export const ArtistBlogDetailsEndPoint = "blog-details";
export const ArtistNewsEndPoint = "news";
export const ArtistNewsdetailsEndPoint = "news-details";
export const ArtistCodeofconductEndPoint = "code-of-conduct";
export const ArtistReleaseFormsEndPoint = "release-forms";
export const ArtistContestEventEndPoint = "contest-and-events";
export const ArtistlicenseAgreementsEndPoint = "license-agreements";
export const ArtistResourcesEndPoint = "resources";
export const ArtistResourcesDetailsEndPoint = "resource";
export const ArtistContestDetailsEndPoint = "contest-and-events";
export const ArtistUploadEndPoint = "upload-guideline";
export const ArtistInfo = "contributor/profile";
export const ArtistUpdateProfile = "contributor/update-profile";
export const ArtistEdit = "contributor/update-password";
export const ArtistUpload = "contributor/product/upload";
export const ArtistCategory = "contributor/product/categories";
export const ArtistTags = "contributor/tags";
export const ArtistTips = "contributor/products/item-types";
export const ArtistMotionTips = "contributor/products/motion-types";
export const ArtistCheckStore = "contributor/cheque/store";
export const ArtistCheckList = "contributor/cheque/list";
export const ArtistPaypalList = "contributor/paypal-details/list";
export const ArtistPaypalStore = "contributor/paypal-details/store";
export const ArtistEarning = "contributor/product/seller-earnings";
export const ArtistEarningReports =
	"contributor/product/seller-earnings-report";
export const ArtistUploadWorks = "contributor/product/uploaded-work";
export const ArtistUploadWorksDelete = "contributor/product/delete";
export const TipofTip = "contributor/artists-corner/tip-of-day";
export const RemovalPoint = "contributor/product/removal-points";
export const Deleteremoval = "contributor/product/removal";
export const DownloadImage = "products/downloadfree";
export const DownloadImageUrl = "products/download-get";
// blog post a comments
export const postComments = "contributor/artists-corner/blog-comment";
// export const postComments = "contributor/blog-comment";
