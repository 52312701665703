import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Select from "react-select";
import ColourOptions from "./data";
import "../../assets/css/upload.css";
// Images Import Here
import { clip, backArrow, pinIcon } from "../../constant";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput } from "../../components/InputField/input";
import swal from "sweetalert";
import {
	useContributorCategorysQuery,
	useContributorMotionTipeQuery,
	useContributorTagsQuery,
	useContributorTipeQuery,
	useContributorUploadMutation,
} from "../../store/services/Contributor/AuthContributorServices";
import ShowError from "./ShowError";
import Backhistory from "../../components/Backhistory";
import { Modal } from "react-bootstrap";
import { useTermsconditionQuery } from "../../store/services/ContactServices";
import CreatableSelect from "react-select/creatable";

const fileTypes = ["JPEG", "PNG", "GIF"];
const VideoTypes = ["MP4", "MOV", "WMV"];
const FilesTypes = ["pdf"];

const Upload22 = () => {
	const [show, setShow] = useState(false);
	const [UploadData, response] = useContributorUploadMutation();
	const { data: uploadTerm, isLoading } = useTermsconditionQuery();
	const Term = uploadTerm?.data?.content;
	const { data } = useContributorCategorysQuery();
	const { data: tipe } = useContributorTipeQuery();
	const { data: tags, refetch } = useContributorTagsQuery();
	const handleClose = () => setShow(false);
	const [selectTipe, setSelectTipe] = useState("");
	const schema = yup.object().shape({
		name: yup.string().required("Name is Required"),
		location: yup.string().required("Location is Required"),
		description: yup.string().required("Description is required"),
		capture_date: yup.string().required("Select Your Capture date"),
		item_type: yup.string().required("Select Your Type"),
		type: yup.string().required("Select Your Product Type"),
		motion_type: yup.string().required("Select Your Motion Type"),
		category_id: yup.string().required("Select Your Category Type"),
		// image: yup
		// 	.mixed()
		// 	.required("Please Provide Image file")
		// 	.test("fileSize", "This file is too large", (value) => {
		// 		return value && value[0].size <= 1000000;
		// 	}),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setError,
		watch,
		getValues,
		setValue,
		control,
	} = useForm({
		resolver: yupResolver(schema),
	});
	const values = watch();

	useEffect(() => {
		refetch();
	}, []);

	const [currentFruit, setCurrentFruit] = useState("");
	const [currentMotion, setCurrentMotion] = useState("");
	const [multipleError, setMultipleError] = useState([]);
	const [subError, subErrors] = useState("");
	const { data: motionTipe } = useContributorMotionTipeQuery(values?.type);
	const today = new Date().toISOString().split("T")[0];
	const handleShow = () => setShow(true);
	const [captureData, setCaptureDate] = useState(today);
	const Category = data?.data;
	const Tags = tags?.data;
	const Tipe = tipe?.data?.itemtype;
	const MotionTipe = motionTipe?.data?.motiontype;
	const Productstipe = tipe?.data?.producttype;
	const [Tagsdta, setTagdata] = useState([]);
	let setDatsse;

	useEffect(() => {
		const formattedTags = Tags?.map((item) => ({
			label: item.tag,
			value: item.tag,
			id: item.id,
		}));
		setTagdata(formattedTags);
	}, [Tags]);

	const dot = (color = "green") => ({
		alignItems: "center",
		display: "flex",
	});

	const colourStyles = {
		control: (styles) => ({
			...styles,
			backgroundColor: "#141737",
			minHeight: "47px",
		}),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				backgroundColor: isDisabled
					? undefined
					: isSelected
					? data.color
					: isFocused
					? "#00bbff"
					: undefined,
			};
		},
		input: (styles) => ({ ...styles, ...dot() }),
	};
	const [selectedOption, setOptionSelect] = useState(null);
	const [errorss, setErrorss] = useState("");
	const navigate = useNavigate();

	const handleTagChange = (newValue) => {
		console.log(newValue, "asdksad");
		setOptionSelect(newValue);
	};

	// Handle tag deletion
	const handleCancel = (value) => {
		const filtered = selectedOption?.filter((item) => item?.value !== value);
		setOptionSelect(filtered);
	};
	const changeFruit = (e) => {
		setCurrentFruit(e.target.value);
	};
	const changeMotion = (newFruit) => {
		setCurrentMotion(newFruit);
	};

	const [half, sethalf] = useState(false);
	const [checked, setChecked] = useState(false);
	const handleChangecheck = () => {
		setChecked(!checked);
	};
	const [checkedTerm, setCheckedTerm] = useState(false);
	const handleChangecheckterm = () => {
		setCheckedTerm(!checkedTerm);
	};
	const [file, setFile] = useState(null);

	const [typevdoError, setTypeVdoError] = useState("MP4/MOV/WMV");
	const handleVdoType = (err) => {
		setTypeVdoError(err);
		setTimeout(() => {
			setTypeVdoError("MP4/MOV/WMV");
		}, 1000);
		// console.log(err);
	};
	const [typeError, setTypeError] = useState("Png/JPEG/JPG");
	const handleType = (err) => {
		setTypeError(err);
		setTimeout(() => {
			setTypeError("Png/JPEG/JPG");
		}, 1000);
		// console.log(err);
	};
	console.log(typeError);
	const handleChange = (file) => {
		setFile(file);
		console.log(file, "ioweiwe");
	};
	const [videos, setVideo] = useState(null);
	const handleChangeVideo = (file) => {
		setVideo(file);
	};

	const [filese, setFilese] = useState(null);
	const [categoryid, setCategoryId] = useState(null);
	const handleChange2 = (file) => {
		setFilese(file);
	};
	const [filese2, setFilese2] = useState(null);
	const handleChange3 = (file) => {
		setFilese2(file);
	};

	const HandleImageCancel = () => {
		setFile(null);
	};
	const HandleVideoCancel = () => {
		setVideo(null);
	};
	const HandlePdfCancel = () => {
		setFilese(null);
	};
	const HandlePdf2Cancel = () => {
		setFilese2(null);
	};

	useEffect(() => {
		if (window.innerWidth <= 1280) {
			sethalf(true);
		}
	}, []);

	const onSubmitHandler = (data) => {
		const tag_ids = selectedOption
			?.filter((item) => item.id)
			.map((item) => item.id);
		const tag_names = selectedOption
			?.filter((item) => !item.id)
			.map((item) => item.label);
		console.log(selectedOption, "asdk2");
		if (filese === null) {
			subErrors("relase1");
			document
				.getElementById("release_form_1")
				.previousSibling.scrollIntoView();
		} else if (filese2 === null) {
			subErrors("relase2");
			document
				.getElementById("release_form_2")
				.previousSibling.scrollIntoView();
		} else if (selectedOption === null) {
			subErrors("tag");
			document.getElementById("tag_ids").previousSibling.scrollIntoView();
		} else if (checkedTerm === false) {
			subErrors("term");
			document.getElementById("term").previousSibling.scrollIntoView();
		} else if (videos === null && file === null) {
			document.getElementById("image").previousSibling.scrollIntoView();
			subErrors("image");
		} else {
			subErrors("");
			let formData = new FormData();
			formData.append("name", data?.name);
			formData.append("capture_date", data?.capture_date);
			formData.append("location", data?.location);
			formData.append("description", data?.description);
			formData.append("item_type", data?.item_type);
			formData.append("type", data?.type);
			formData.append("motion_type", data?.motion_type);
			formData.append("is_drone", 1);
			formData.append("terms_and_condition", 1);
			formData.append("category_id", data?.category_id);
			formData.append("tag_ids", tag_ids);
			formData.append("tag_names", tag_names);
			formData.append("video", videos);
			formData.append("image", file);
			formData.append("release_form_1[]", filese);
			formData.append("release_form_2[]", filese2);
			UploadData(formData);
		}
	};

	useEffect(() => {
		if (response?.isError) {
			if (response?.isError && response?.error?.data?.errors) {
				for (let key in response?.error?.data?.errors) {
					if (response?.error?.data?.errors.hasOwnProperty(key)) {
						document.getElementById(key).previousSibling.scrollIntoView();
						setMultipleError([
							{
								type: key,
								message: response?.error?.data?.errors[key][0],
							},
						]);
					}
				}
			}
		}
		if (response?.isError && response?.error?.data?.errors) {
			setError("name", {
				type: "manual",
				message: response?.error?.data?.errors?.name,
			});
			setError("capture_date", {
				type: "manual",
				message: response?.error?.data?.errors?.capture_date,
			});
			setError("location", {
				type: "manual",
				message: response?.error?.data?.errors?.location,
			});
			setError("description", {
				type: "manual",
				message: response?.error?.data?.errors?.description,
			});
			setError("item_type", {
				type: "manual",
				message: response?.error?.data?.errors?.item_type,
			});
			setError("motion_type", {
				type: "manual",
				message: response?.error?.data?.errors?.motion_type,
			});
			setError("type", {
				type: "manual",
				message: response?.error?.data?.errors?.type,
			});
		}
		if (response?.error?.data?.message) {
			setErrorss(response?.error?.data?.message);
			setTimeout(() => {
				setErrorss("");
			}, 5000);
		}
	}, [response?.isError]);

	useEffect(() => {
		if (response?.isSuccess) {
			swal(
				"Success!",
				"Your product has been submitted and will be available for purchase once approved by the admin ",
				"success",
			);
			reset();
			setSelectTipe("");
			setCurrentFruit("");
			setCurrentMotion("");
			setCategoryId(null);
			setFile(null);
			setFilese(null);
			setFilese2(null);
			setOptionSelect(null);
			setVideo(null);
			navigate("/upload-work");
		}
	}, [response.isSuccess]);

	const customStyles = {
		input: (provided) => ({
			...provided,
			color: "#fff", // Dark text color for typed input
		}),
		option: (provided, state) => ({
			...provided,
			backgroundColor: state.isFocused ? "#00bbff" : "#05092c", // Dark background on hover
			color: "#fff", // White text color for options
		}),
		// multiValue: (provided) => ({
		// 	...provided,
		// 	backgroundColor: "#333", // Dark background for selected tags
		// }),
		// multiValueLabel: (provided) => ({
		// 	...provided,
		// 	color: "#fff", // White text for selected tags
		// }),
		// multiValueRemove: (provided) => ({
		// 	...provided,
		// 	color: "#fff",
		// 	":hover": {
		// 		backgroundColor: "#555",
		// 		color: "#fff",
		// 	},
		// }),
	};

	return (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header Start Here */}
			<section className="uploadimage">
				<div className="container">
					<div className="testimonial_start">
						<Backhistory classNa={"back_icon text-end mt-5"} />
						<div className={half ? "col-lg-10 mx-auto" : "col-lg-8 mx-auto"}>
							<div className="form_start">
								<div className="top_text text-center">
									<h3>Upload Image / Videos</h3>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									</p>

									<p className="SubErrrors">
										{errorss !== "Bad Request!" && errorss}
									</p>
								</div>
								<form onSubmit={handleSubmit(onSubmitHandler)}>
									<div className="form-group">
										<label htmlFor="title">
											Title<span>*</span>
										</label>
										<FormInput
											id="name"
											name="name"
											type="text"
											placeholder="Enter title"
											register={register}
											error={errors.name}
										/>
									</div>
									<div className="form-group">
										<label htmlFor="description">
											Description<span>*</span>
										</label>
										<FormInput
											id="description"
											name="description"
											type="text"
											placeholder="Enter description here"
											register={register}
											error={errors.description}
										/>
									</div>
									<div className="form-group">
										<label htmlFor="location">
											Location <span>*</span>
										</label>
										<FormInput
											id="location"
											name="location"
											type="text"
											placeholder="Country / State / City"
											register={register}
											error={errors.location}
										/>
									</div>
									<div className="form-group">
										<label htmlFor="image">
											Image Capture Date<span>*</span>
										</label>
										<input
											{...register("capture_date", { required: true })}
											type="date"
											className={
												errors?.capture_date?.message !== undefined
													? "form-control setforminput hover_input"
													: "form-control hover_input"
											}
											name="capture_date"
											max={today}
											placeholder="Enter Date"
											defaultValue={today}
										/>
										<p className="errormessage">
											{errors?.capture_date && (
												<div>{errors?.capture_date?.message}</div>
											)}
										</p>
										{/* <input
											
											value={captureData}
											onChange={(e) => setCaptureDate(e.target.value)}
										/> */}
									</div>
									<div className="form-group">
										<label htmlFor="Type">
											Item Type<span>*</span>
										</label>
										<select
											{...register("item_type", { required: true })}
											className={
												errors?.item_type?.message !== undefined
													? "form-control setforminput hover_input"
													: "form-control hover_input"
											}
											name="item_type"
										>
											<option selected disabled value="">
												Select Item Type
											</option>
											{Tipe?.map((item, index) => {
												return (
													<option value={item?.name} key={index}>
														{item?.name}
													</option>
												);
											})}
										</select>
										<p className="errormessage">
											{errors?.item_type && (
												<div>{errors?.item_type?.message}</div>
											)}
										</p>
									</div>
									<div className="form-group">
										<label htmlFor="Type">
											Product Type<span>*</span>
										</label>
										<select
											{...register("type", { required: true })}
											className={
												errors?.type?.message !== undefined
													? "form-control setforminput hover_input"
													: "form-control hover_input"
											}
											name="type"
										>
											<option selected disabled value="">
												Select Product Type
											</option>

											{Productstipe?.map((item, index) => {
												return (
													<option value={item?.name} key={index}>
														{item?.name}
													</option>
												);
											})}
										</select>
										<p className="errormessage">
											{errors?.type && <div>{errors?.type?.message}</div>}
										</p>

										{/* Select Still Image / Select Motion Picture Image*/}
										<div className="form-group">
											<label htmlFor="motion">
												{values?.type === "still"
													? "Select Still Image"
													: "Select Motion Picture Image"}
												<span>*</span>
											</label>
											<select
												{...register("motion_type", { required: true })}
												name="motion_type"
												className={
													errors?.motion_type?.message !== undefined
														? "form-control setforminput hover_input"
														: "form-control hover_input"
												}
											>
												<option selected disabled value="">
													Select Motion Type
												</option>

												{MotionTipe?.map((item, index) => {
													return (
														<option value={item?.name} key={index}>
															{item?.name}
														</option>
													);
												})}
											</select>
											<p className="errormessage">
												{errors?.motion_type && (
													<div>{errors?.motion_type?.message}</div>
												)}
											</p>
										</div>
									</div>
									<div className="form-group">
										<div className="form-submit-terms">
											<div className="mb-3 form-check">
												<input
													type="checkbox"
													className="form-check-input"
													id="exampleCheck1"
													onChange={handleChangecheck}
												/>
												<label
													className="form-check-label"
													htmlFor="exampleCheck1"
												>
													Was this image captured using a drone ?
												</label>
											</div>
										</div>
										{subError === "drown" && (
											<p className="SubErrrors">Please Check This Terms</p>
										)}
									</div>
									<div className="form-group">
										<label htmlFor="Category">
											Category<span>*</span>
										</label>
										<select
											{...register("category_id", { required: true })}
											className={
												errors?.category_id?.message !== undefined
													? "form-control setforminput hover_input"
													: "form-control hover_input"
											}
											name="category_id"
										>
											<option selected disabled value="">
												Select Category
											</option>
											{Category?.map((item, index) => {
												return (
													<option value={item?.id} key={index}>
														{item?.name}
													</option>
												);
											})}
										</select>
										<p className="errormessage">
											{errors?.category_id && (
												<div>{errors?.category_id?.message}</div>
											)}
										</p>
									</div>
									<div className="form-group" id="tag_ids">
										<label htmlFor="tags">
											Tags<span>*</span>
										</label>

										<CreatableSelect
											isMulti
											value={selectedOption}
											options={Tagsdta}
											onChange={handleTagChange}
											styles={customStyles}
											name="tag_ids"
											className="basic-multi-select"
											classNamePrefix="select"
										/>
										{subError === "tag" && (
											<p className="SubErrrors">Please Select Tags</p>
										)}
										<ShowError errorArray={multipleError} type="tag_ids" />
									</div>
									<div className="form-group">
										<label htmlFor="">Tags Selected</label>
										<div className="tags-flex">
											{selectedOption?.map((item, index) => {
												return (
													<button
														type="button"
														className="single_tag"
														key={index}
													>
														<span className="tag-name">{item?.label}</span>
														<span
															className="cross"
															onClick={() => handleCancel(item?.value)}
														>
															x
														</span>
													</button>
												);
											})}
											{/* <p>
												{checked ? (
													<button type="button" className="single_tag">
														<span className="tag-name">Drone</span>
														<span className="cross">x</span>
													</button>
												) : (
													""
												)}
											</p> */}
										</div>
									</div>
									{values?.item_type === "video" ? (
										<div className="form-group" id="image">
											<div className="file_upload_system">
												<label htmlFor="">
													Upload Video<span>*</span>
												</label>
												<div className="file_wrapper">
													<FileUploader
														handleChange={handleChangeVideo}
														name="video"
														onTypeError={handleVdoType}
														types={VideoTypes}
													/>
													<span className="err">
														<img
															src={pinIcon}
															alt="123"
															className="img-fluid"
														/>
														<ruby>{typevdoError}</ruby>
													</span>
												</div>
												<figure className="clip_imgset">
													<img src={clip} alt="" />
												</figure>
											</div>
											{videos !== null && (
												<div className="tags-flex">
													<button type="button" className="single_tag">
														<span className="tag-name">{videos?.name}</span>
														{/* <span className="cross" onClick={HandleVideoCancel}>
															x
														</span> */}
													</button>
												</div>
											)}

											{subError === "image" && (
												<p className="SubErrrors">This file is required.</p>
											)}
											<ShowError errorArray={multipleError} type="video" />
										</div>
									) : (
										<div className="form-group" id="image">
											<div className="file_upload_system">
												<label htmlFor="">
													Upload Image<span>*</span>
												</label>
												<div className="file_wrapper">
													<FileUploader
														onTypeError={handleType}
														handleChange={handleChange}
														name="image"
														types={fileTypes}
														// label={typeError}
													/>
													<span className="err">
														<img
															src={pinIcon}
															alt="123"
															className="img-fluid"
														/>
														<ruby>{typeError}</ruby>
													</span>
												</div>
												{/* <figure className="clip_imgset">
													<img src={clip} alt="" />
												</figure> */}
											</div>
											{file !== null && (
												<div className="tags-flex">
													<button type="button" className="single_tag">
														<span className="tag-name">{file?.name}</span>
														{/* <span className="cross" onClick={HandleImageCancel}>
															x
														</span> */}
													</button>
												</div>
											)}
											{subError === "image" && (
												<p className="SubErrrors">This file is required.</p>
											)}
											<ShowError errorArray={multipleError} type="image" />
										</div>
									)}

									<div className="form-group" id="release_form_1">
										<div className="file_upload_system">
											<input type="text" hidden />
											<label htmlFor="">
												Upload Release Form
												<span>* (Note: You can only upload PDF)</span>
											</label>
											<div className="file_wrapper">
												<FileUploader
													handleChange={handleChange2}
													name="release_form_1"
													types={FilesTypes}
												/>
												<span className="err">
													<img src={pinIcon} alt="123" className="img-fluid" />
													<ruby>Upload or drop a file right here</ruby>
												</span>
											</div>
											<figure className="clip_imgset">
												<img src={clip} alt="" />
											</figure>
										</div>
										{filese !== null && (
											<div className="tags-flex">
												<button type="button" className="single_tag">
													<span className="tag-name">{filese?.name}</span>
													{/* <span className="cross" onClick={HandlePdfCancel}>
														x
													</span> */}
												</button>
											</div>
										)}
										{subError === "relase1" && (
											<p className="SubErrrors">Please Add One Pdf File</p>
										)}
										<ShowError
											errorArray={multipleError}
											type="release_form_1.0"
										/>
									</div>
									<div className="form-group" id="release_form_2">
										<div className="file_upload_system">
											<input type="text" hidden />
											<label htmlFor="">
												Upload Release Form
												<span>* (Note: You can only upload PDF)</span>
											</label>
											<div className="file_wrapper">
												<FileUploader
													handleChange={handleChange3}
													name="release_form_2"
													types={FilesTypes}
												/>
												<span className="err">
													<img src={pinIcon} alt="123" className="img-fluid" />
													<ruby>Upload or drop a file right here</ruby>
												</span>
											</div>
											<figure className="clip_imgset">
												<img src={clip} alt="" />
											</figure>
										</div>
										{filese2 !== null && (
											<div className="tags-flex">
												<button type="button" className="single_tag">
													<span className="tag-name">{filese2?.name}</span>
													{/* <span className="cross" onClick={HandlePdf2Cancel}>
														x
													</span> */}
												</button>
											</div>
										)}
										{subError === "relase2" && (
											<p className="SubErrrors">Please Add One Pdf File</p>
										)}
										<ShowError
											errorArray={multipleError}
											type="release_form_2.0"
										/>
									</div>

									<ul className="terms-lines">
										<li>
											All Images and Videos are subject to approval by Moonscape
											sweet imagery
										</li>
										<li>
											Approval time may very based on the volume of submission
										</li>
										<li>
											Please review our{" "}
											<span
												// href="/terms"
												onClick={handleShow}
												style={{
													textDecoration: "underline",
													cursor: "pointer",
												}}
											>
												{" "}
												Terms & Conditions{" "}
											</span>{" "}
											for more information
										</li>
									</ul>
									<div className="form-group" id="terms">
										<div className="form-submit-terms">
											<div className="form-check">
												<input
													id="teck"
													className="form-check-input"
													type="checkbox"
													onChange={handleChangecheckterm}
												/>
												<label
													className="form-check-label"
													htmlFor="teck"
													name="terms"
												>
													I have read and agree to Terms and Conditions
												</label>
											</div>

											<button className="btn submit_upload_form" type="submit">
												{response?.isLoading ? "Loading..." : "Upload"}
											</button>
										</div>
										{subError === "term" && (
											<p className="SubErrrors">
												Please Read Term & Condition And Check{" "}
											</p>
										)}
									</div>
								</form>
							</div>
						</div>
					</div>
					<Modal
						show={show}
						onHide={handleClose}
						backdrop="static"
						keyboard={false}
						className="contactmodal remove-content"
					>
						<Modal.Header closeButton className="modal_body"></Modal.Header>
						<Modal.Body>
							<div className="contact-form-modal-content-wrapper">
								<img src="" className="img-fluid remove-content" alt="" />
								<h2 className="py-3">Terms & Conditions</h2>
								<p
									className="term_modal_content"
									dangerouslySetInnerHTML={{
										__html: Term?.value,
									}}
								></p>
							</div>
						</Modal.Body>
					</Modal>
				</div>
			</section>
			{/* Footer Start Here */}
			<Footer />
			{/* Footer Start Here */}
		</>
	);
};
export default Upload22;
