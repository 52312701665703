import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const Private = () => {
	const { contrubutorToken } = useSelector((state) => state.AuthReducer);
	const { userToken } = useSelector((state) => state.AuthReducer);
	return contrubutorToken !== "" ? (
		<Outlet />
	) : userToken !== "" ? (
		window.history.back()
	) : (
		<Navigate to="/Login-page" />
	);
};
export default Private;
