import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {
	ArtistCornerEndPoint,
	ArtistNewsEndPoint,
	ArtistNewsdetailsEndPoint,
	main_blog,
} from "../../../utils/Urls";

const ArtistNewsService = createApi({
	reducerPath: "artist-news-service",
	baseQuery: fetchBaseQuery({
		baseUrl: "https://admin-moonscape.developer-ourbase-camp.com/api/v3/",
		prepareHeaders: (headers, { getState }) => {
			const reducers = getState();
			const token = reducers?.AuthReducer?.contrubutorToken;
			headers.set("authorization", token ? `Bearer ${token}` : "");
			headers.set("Access-Control-Allow-Origin", "*");
			return headers;
		},
	}),
	endpoints: (builder) => {
		return {
			artistnewsContent: builder.query({
				query: (page) => {
					return {
						url: `${ArtistCornerEndPoint}/${ArtistNewsEndPoint}?page=${page}`,
						method: "Get",
					};
				},
			}),
			artistBlogContent: builder.query({
				query: (data) => {
					return {
						url: `${ArtistCornerEndPoint}/${main_blog}?page=${data?.page}&slug=${data?.slug}`,
						method: "Get",
					};
				},
			}),
			artistnewsdetailsContent: builder.query({
				query: (id) => {
					return {
						url: `${ArtistCornerEndPoint}/${ArtistNewsdetailsEndPoint}/${id}`,
						method: "Get",
					};
				},
			}),
		};
	},
});

export const {
	useArtistnewsContentQuery,
	useArtistnewsdetailsContentQuery,
	useArtistBlogContentQuery,
} = ArtistNewsService;
export default ArtistNewsService;
