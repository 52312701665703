import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const UserRoute = () => {
	const { userToken } = useSelector((state) => state.AuthReducer);
	const { contrubutorToken } = useSelector((state) => state.AuthReducer);
	return userToken !== "" ? (
		<Outlet />
	) : contrubutorToken !== "" ? (
		// back karna hai page pe jis page tha phele us mein
		window.history.back()
	) : (
		<Navigate to="/Login-page" />
	);
};
export default UserRoute;
