import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BaseUrl, aboutPage, employeesData, homePage } from "../../utils/Urls";

const HomeServices = createApi({
	reducerPath: "homedata",
	baseQuery: fetchBaseQuery({
		baseUrl: BaseUrl,
	}),
	endpoints: (builder) => {
		return {
			homePage: builder.query({
				query: () => {
					return {
						url: homePage,
						method: "GET",
					};
				},
			}),
			aboutusPage: builder.query({
				query: () => {
					return {
						url: aboutPage,
						method: "GET",
					};
				},
			}),
			employe: builder.query({
				query: () => {
					return {
						url: employeesData,
						method: "GET",
					};
				},
			}),
		};
	},
});

export const { useHomePageQuery, useAboutusPageQuery,useEmployeQuery } = HomeServices;
export default HomeServices;
