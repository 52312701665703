import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import Home from "../pages/Home/Home";
import "../assets/css/variables.css";
import "../assets/css/style.css";
import Login from "../pages/auth/Login";
import ForgetPasswordEmail from "../pages/auth/ForgetPasswordEmail";
import ForgetPassword from "../pages/auth/ForgetPassword";
import RegisterPage from "../pages/auth/RegisterPage";
import RegisterBuyer from "../pages/auth/RegisterBuyer";
import RegisterContributor from "../pages/auth/RegisterContributor";
import LoginCont from "../pages/auth/LoginCont";
import About from "../pages/About/About";
import Contact from "../pages/contact/contact";
import Faq from "../pages/Faq/Faq";
import Terms from "../pages/Policy/Terms";
import Policy from "../pages/Policy/Policy";
import MyAccout from "../pages/UserProfile/MyAccout";
import MySubscription from "../pages/UserProfile/MySubscription";
import BillingInformation from "../pages/UserProfile/BillingInformation";
import DownloadHistory from "../pages/UserProfile/DownloadHistory";
import Testimonials from "../pages/Testimonials/Testimonials";
import Checkout from "../pages/Checkout/Checkout";
import MyFavourite from "../pages/UserProfile/MyFavourite";
import ArtistAccount from "../pages/ArtistPortal/ArtistAccount";
import Agreement from "../pages/Agreement/Agreement";
import WebsiteTerm from "../pages/Agreement/WebsiteTerm";
import PaymentInfo from "../pages/ArtistPortal/PaymentInfo";
import PaymentCheque from "../pages/ArtistPortal/PaymentCheque";
import MyEarnings from "../pages/ArtistPortal/MyEarnings";
import ArtistReport from "../pages/ArtistPortal/ArtistReport";
import UploadedWork from "../pages/ArtistPortal/UploadedWork";
import Blog from "../pages/Blogs/Blog";
import Image from "../pages/Images/Image";
import RemovalContent from "../pages/ArtistPortal/RemovalContent";
import Contributors from "../pages/Contributors/Contributors";
import ArtistProfile from "../pages/ArtistProfile/ArtistProfile";
import HowCanHelp from "../pages/Help/HowCanHelp";
import ArtistCorner from "../pages/ArtistCorner/ArtistCorner";
import ArtistCodeOfConduct from "../pages/ArtistCorner/ArtistCodeOfConduct";
import ArtistUploadGuide from "../pages/ArtistCorner/ArtistUploadGuide";
import ArtistNews from "../pages/ArtistCorner/ArtistNews";
import ArtistNewsDetail from "../pages/ArtistCorner/ArtistNewsDetail";
import ArtistReleaseForm from "../pages/ArtistCorner/ArtistReleaseForm";
import Upload from "../pages/Upload/Upload";
import Upload1 from "../pages/Upload/Upload1";
import ThankYou from "../pages/Cart/ThankYou";
import ProductDetail from "../pages/Product/ProductDetail";
import ArtistLicensingAgreements from "../pages/ArtistCorner/ArtistLicensingAgreements";
import ArtistContest from "../pages/ArtistCorner/ArtistContest";
import ArtistResources from "../pages/ArtistCorner/ArtistResources";
import ArtistContentDetail from "../pages/ArtistCorner/ArtistContentDetail";
import Pricing from "../pages/Pricing/Pricing";
import ArtistMasnory from "../pages/ArtistCorner/ArtistMasnory";
import Sitemap from "../pages/Sitemap/Sitemap";
import BlogDetail from "../pages/Blogs/BlogDetail";
import Videos from "../pages/Videos/Videos";
import Thankyou2 from "../pages/Cart/Thankyou2";
import Copyright from "../pages/Policy/Copyright";
import Tips from "../pages/Policy/Tips";
import ProductDetail2 from "../pages/Product/ProductDetail2";
import Cart2 from "../pages/Cart/Cart2";
import ArtistFavourite from "../pages/ArtistPortal/ArtistFavourite";
import LicenseAgreement from "../pages/LicenseAgreement/LicenseAgreement";
import Releaseform from "../pages/releaseform/releaseform";
import ArtistBlogDetail from "../pages/ArtistCorner/ArtistBlogDetail";
import ForgetPasswordContributor from "../pages/auth/ForgetPasswordContributor";
import NewPasswordContributor from "../pages/auth/NewPasswordContributor";
import Private from "./Private";
import UserAuthRoute from "./UserAuthRoute";
import Public from "./Public";
import UserRoute from "./UserRoute";
import Searching from "../pages/globalsearching/Searching";
import LoginSelectPage from "../pages/auth/LoginSelectPage";
import Notfound from "../components/Notfound";
import Upload22 from "../pages/Upload/Upload22";

const PublicRoutes = () => {
  useEffect(() => {
    // Function to handle resize events
    const handleResize = () => {
      console.log(
        "Screen resized. Width:",
        window.innerWidth,
        "Height:",
        window.innerHeight
      );
    };

    // Add event listener for resize events
    window.addEventListener("resize", handleResize);

    // Call the function once initially to log the current size
    handleResize();

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty dependency array ensures this effect runs only on mount and unmount

  return (
    <BrowserRouter>
      <Routes>
        {/* sitemap starts here */}
        <Route path="/sitemap" element={<Sitemap />} />
        <Route path="/Login-page" element={<LoginSelectPage />} />
        {/* <Route path="/Check-upload" element={<Upload22 />} /> */}
        {/* sitemap ends here */}
        <Route path="/" element={<Home />} /> ✔️
        <Route path="/about" element={<About />} /> ✔️
        <Route path="/contact" element={<Contact />} /> ✔️
        <Route path="/faqs" element={<Faq />} /> ✔️
        <Route path="/terms" element={<Terms />} />
        <Route path="/tips" element={<Tips />} />
        <Route path="/copyright" element={<Copyright />} /> ✔️
        <Route path="/Policy" element={<Policy />} />
        <Route path="/agreement" element={<Agreement />} />
        <Route path="/website-term" element={<WebsiteTerm />} />
        {/* <Route path="/term-use" element={<TermUse />} /> */}
        <Route path="/contributors" element={<Contributors />} />
        <Route path="/help" element={<HowCanHelp />} />
        <Route path="/LicenseAgreement" element={<LicenseAgreement />} />
        <Route path="/blogs" element={<Blog />} />
        <Route path="/blog-detail" element={<BlogDetail />} />
        <Route path="/images" element={<Image />} />
        <Route path="/videos" element={<Videos />} />
        <Route path="/cart" element={<Cart2 />} />
        <Route path="/artist-profile" element={<ArtistProfile />} />
        {/* /cart */}
        <Route path="/searching" element={<Searching />} />
        <Route path="/product-detail" element={<ProductDetail />} />
        <Route path="/video-detail" element={<ProductDetail2 />} />
        <Route path="/pricing/:type?" element={<Pricing />} />
        <Route path="/testimonial" element={<Testimonials />} />
        <Route path="/release-form" element={<Releaseform />} />
        {/* Artist All page wrapper */}
        <Route element={<Private />}>
          <Route path="/artist-corner" element={<ArtistCorner />} />
          <Route
            path="/artist-code-conduct"
            element={<ArtistCodeOfConduct />}
          />
          <Route path="/artist-upload-guide" element={<ArtistUploadGuide />} />
          <Route path="/artist-news" element={<ArtistNews />} />
          <Route path="/artist-news-detail" element={<ArtistNewsDetail />} />
          <Route path="/artist-blog-detail" element={<ArtistBlogDetail />} />
          <Route path="/artist-release-form" element={<ArtistReleaseForm />} />
          <Route
            path="/artist-licensing-agreement"
            element={<ArtistLicensingAgreements />}
          />
          <Route path="/artist-contest" element={<ArtistContest />} />
          <Route path="/artist-recourses" element={<ArtistResources />} />
          <Route
            path="/artist-content-detail"
            element={<ArtistContentDetail />}
          />
          <Route path="/artist-masonry" element={<ArtistMasnory />} />
          {/* Artist starts here */}
          <Route path="/artist-account" element={<ArtistAccount />} />
          <Route path="/payment-info" element={<PaymentInfo />} />
          {/* <Route path="/payment-cheque" element={<PaymentCheque />} /> */}
          <Route path="/my-earnings" element={<MyEarnings />} />
          <Route path="/artist-report" element={<ArtistReport />} />
          <Route path="/upload-work" element={<UploadedWork />} />
          <Route path="/artist-favourite" element={<ArtistFavourite />} />
          <Route path="/removal-content" element={<RemovalContent />} />
          {/* <Route path="/removal-content2" element={<RemovalContent2 />} /> */}
          {/* <Route path="/upload" element={<Upload />} /> */}
          <Route path="/upload" element={<Upload22 />} />
          {/* <Route path="/upload1" element={<Upload1 />} /> */}
          {/* Artist ends here */}
        </Route>
        {/* Artist All page wrapper */}
        {/* Auth starts here */}
        <Route element={<UserAuthRoute />}>
          <Route path="/login" element={<Login />} />
          <Route path="/reset-user-password" element={<ForgetPassword />} />
          <Route path="/forget-password" element={<ForgetPasswordEmail />} />
          <Route path="/register-buyer" element={<RegisterBuyer />} />
          <Route path="/register-page" element={<RegisterPage />} />
        </Route>
        <Route element={<Public />}>
          <Route
            path="/reset-contributor-password"
            element={<NewPasswordContributor />}
          />
          <Route
            path="/forget-password-contributor"
            element={<ForgetPasswordContributor />}
          />
          <Route
            path="/register-contributor"
            element={<RegisterContributor />}
          />
          <Route path="/login-contributor" element={<LoginCont />} />
          <Route path="/register-page" element={<RegisterPage />} />
        </Route>
        {/* Auth ends here */}
        {/* Profile starts here */}
        <Route element={<UserRoute />}>
          <Route path="/myaccount" element={<MyAccout />} />
          <Route path="/mySubscription" element={<MySubscription />} />
          <Route path="/billingInformation" element={<BillingInformation />} />
          <Route path="/download-history" element={<DownloadHistory />} />
          <Route path="/myfavourite" element={<MyFavourite />} />

          <Route path="/checkout" element={<Checkout />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/thankyou" element={<Thankyou2 />} />
        </Route>
        {/* Profile ends here */}
        {/* <Route path="/cart" element={<Cart />} /> */}
        <Route path="*" element={<Notfound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default PublicRoutes;
