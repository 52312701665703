import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/resgister.css";
import * as yup from "yup";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput } from "../../components/InputField/input";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import EyesIcon from "../../components/InputField/EyesIcon";
import { useContributorRegisterMutation } from "../../store/services/Contributor/AuthContributorServices";
import Modal from "react-bootstrap/Modal";

import { backArrow } from "../../constant";
import Backhistory from "../../components/Backhistory";
import { Button } from "react-bootstrap";
import {
	useAgreementDataQuery,
	useTermsconditionQuery,
} from "../../store/services/ContactServices";

const RegisterContributor = () => {
	const { data, isLoading } = useTermsconditionQuery();
	const Term = data?.data?.content;
	const [Signupdata, response] = useContributorRegisterMutation();
	const [showss, setShows] = useState(false);
	const handleClose = () => setShowModal(false);
	const [showModal, setShowModal] = useState(false);
	const handleShowAgreement = () => setShowAgreement(true);
	const handleClosed = () => setShowAgreement(false);
	const [agreement, setShowAgreement] = useState(false);
	const { data: dataAgree, isLoad } = useAgreementDataQuery();
	const ContentList = dataAgree?.data?.list;
	const AgreementContent = dataAgree?.data?.description;
	const handleShow = () => setShowModal(true);
	const [showss1, setShow1] = useState(false);
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	const schema = yup.object().shape({
		email: yup.string().email().required("Email is a required field"),
		first_name: yup
			.string()
			.min(3, "Write a first name more than 3 letters.")
			.max(15, "First name cannot be greater than 15 characters long")
			.required("First name is required"),
		last_name: yup
			.string()
			.min(2, "Write a last name more than 3 letters.")
			.max(15, "Last name cannot be greater than 15 characters long")
			.required("Last name is required"),
		phone: yup.string().matches(phoneRegExp, "Phone number is not valid"),
		password: yup
			.string()
			.matches(/^\S*$/, "Whitespace is not allowed")
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%^&*()+=]).{8,12}$/,
				"Password should contain between 8 to 12 characters, at least one digit, one uppercase letter, and one symbol",
			)
			.required("Password is required"),

		password_confirmation: yup
			.string()
			.oneOf([yup.ref("password"), null], "Passwords must match")
			.required("Confirm Password is required"),
	});
	const [checkedTerm, setCheckedTerm] = useState(false);
	const handleChangecheckterm = (e) => {
		setCheckedTerm(e.target.checked);
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setError,
		getValues,
		setValue,
	} = useForm({
		resolver: yupResolver(schema),
	});

	console.log(errors, "asdas");
	const navigate = useNavigate();
	const [errorss, setErrorss] = useState("");

	useEffect(() => {
		if (response?.isError && response?.error?.data?.errors) {
			setError("first_name", {
				type: "manual",
				message: response?.error?.data?.errors?.first_name,
			});
			setError("email", {
				type: "manual",
				message: response?.error?.data?.errors?.email,
			});
			setError("last_name", {
				type: "manual",
				message: response?.error?.data?.errors?.last_name,
			});
			setError("phone", {
				type: "manual",
				message: response?.error?.data?.errors?.phone,
			});
			setError("password", {
				type: "manual",
				message: response?.error?.data?.errors?.password,
			});
			setError("password_confirmation", {
				type: "manual",
				message: response?.error?.data?.errors?.password_confirmation,
			});
		}

		if (response?.error?.data?.message) {
			setErrorss(response?.error?.data?.message);
			setTimeout(() => {
				setErrorss("");
			}, 5000);
		}
	}, [response?.isError]);

	// const onSubmitHandler = (data) => {
	// 	console.log(data, "dsksd");
	// 	if (checkedTerm) {
	// 		let formData = new FormData();
	// 		formData.append("first_name", data?.first_name);
	// 		formData.append("last_name", data?.last_name);
	// 		formData.append("phone", data?.phone);
	// 		formData.append("email", data?.email);
	// 		formData.append("password", data?.password);
	// 		formData.append("password_confirmation", data?.password_confirmation);
	// 		Signupdata(formData);
	// 	} else {
	// 		swal("Error!", "Please Check Terms & Condition", "error");
	// 	}
	// };

	const onSubmitHandler = (data) => {
		console.log("Form data: ", data); // Check if this logs
		if (checkedTerm) {
			let formData = new FormData();
			formData.append("first_name", data?.first_name);
			formData.append("last_name", data?.last_name);
			formData.append("phone", data?.phone);
			formData.append("email", data?.email);
			formData.append("password", data?.password);
			formData.append("password_confirmation", data?.password_confirmation);
			Signupdata(formData);
		} else {
			swal("Error!", "Please Check Terms & Condition", "error");
		}
	};

	useEffect(() => {
		if (response?.isSuccess) {
			swal({
				title: "Signup Success!",
				text: response?.data?.message,
				icon: "success",
				html: true,
				className: "sign-up-modal",
			});
			reset();
			navigate("/login-contributor");
		}
	}, [response.isSuccess]);

	return (
		<>
			<Header />
			{/* register sec starts here */}
			<section className="register-sec">
				<div className="container">
					<Backhistory classNa={"back_icon text-end"} />
					<div className="row pt-3">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="register-card-wrapper">
								<div className="register-heading-wrapper">
									<h2>Become A Contributor</h2>
									<p>
										Already have an account ?{" "}
										<a href="/login-contributor">Login</a>
									</p>
								</div>
								<div className="register-form-wrapper mt-5">
									<form onSubmit={handleSubmit(onSubmitHandler)}>
										<div className="form-group">
											<div className="pass-label-wrapper">
												<label htmlFor="first_name">
													First Name <span>*</span>
												</label>
											</div>
											<FormInput
												id="first_name"
												name="first_name"
												type="text"
												placeholder="First Name"
												register={register}
												error={errors.first_name}
											/>
										</div>
										<div className="form-group">
											<label htmlFor="last_name">
												Last Name <span>*</span>
											</label>
											<FormInput
												id="last_name"
												name="last_name"
												type="text"
												placeholder="Last Name"
												register={register}
												error={errors.last_name}
											/>
										</div>
										<div className="form-group">
											<label htmlFor="">
												Enter email <span>*</span>
											</label>
											<FormInput
												id="email"
												name="email"
												type="email"
												placeholder="Email Address"
												register={register}
												error={errors.email}
											/>
										</div>
										<div className="form-group">
											<label htmlFor="">
												Contact Number <span>*</span>
											</label>
											<FormInput
												id="phone"
												name="phone"
												type="number"
												placeholder="Contact Number"
												register={register}
												error={errors.phone}
											/>
										</div>
										<div className="form-group setEyesIcon">
											<label htmlFor="password">
												Password <span>*</span>
											</label>
											<FormInput
												id="password"
												name="password"
												type={showss ? "text" : "password"}
												placeholder="Password"
												register={register}
												error={errors.password}
											/>
											<EyesIcon showss={showss} setShow={setShows} />
										</div>
										<div className="form-group setEyesIcon">
											<label htmlFor="password_confirmation">
												Confirm Password <span>*</span>
											</label>
											<FormInput
												id="password_confirmation"
												name="password_confirmation"
												type={showss1 ? "text" : "password"}
												placeholder="Confirm Password"
												register={register}
												error={errors.password_confirmation}
											/>
											<EyesIcon
												showss={showss1}
												setShow={setShow1}
												className="Myeyes"
											/>
										</div>
										<div className="setFonts">
											<label
												className="custom_check_box1"
												htmlFor="privacyCheckbox"
											>
												{/* <Button
													type="button"
													className="btn remove2"
													variant="primary"
													onClick={handleShow}
												>
													Save & Proceed
												</Button> */}
												<span
													// href="/terms"
													onClick={handleShow}
													style={{ textDecoration: "underline" }}
												>
													{" "}
													Terms & Conditions{" "}
												</span>{" "}
												and
												<span
													style={{
														textDecoration: "underline",
														margin: "0px 5px",
													}}
													onClick={handleShowAgreement}
												>
													Website Terms of Use
												</span>
												<input
													type="checkbox"
													id="privacyCheckbox"
													onChange={(e) => handleChangecheckterm(e)}
												/>
												<span className="checkmark1"></span>
											</label>
										</div>
										<div className="form-group">
											<button className="btn form-control" type="submit">
												{response?.isLoading ? "Loading..." : "Register"}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
				</div>
			</section>
			{/* register sec ends here */}
			<Footer />
			<Modal
				show={showModal}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
				className="contactmodal remove-content"
			>
				<Modal.Header closeButton className="modal_body"></Modal.Header>
				<Modal.Body>
					<div className="contact-form-modal-content-wrapper">
						<img src="" className="img-fluid remove-content" alt="" />
						<h2 className="py-3">Terms & Conditions</h2>
						<p
							className="term_modal_content"
							dangerouslySetInnerHTML={{
								__html: Term?.value,
							}}
						></p>
					</div>
				</Modal.Body>
			</Modal>

			{/* Agrement Data Show */}

			<Modal
				show={agreement}
				onHide={handleClosed}
				backdrop="static"
				keyboard={false}
				className="contactmodal remove-content"
			>
				<Modal.Header closeButton className="modal_body"></Modal.Header>
				<Modal.Body>
					<div className="contact-form-modal-content-wrapper">
						<img src="" className="img-fluid remove-content" alt="" />
						<div className="testimonials-wrapper">
							<h3>Contributor Agreement</h3>
						</div>
						<div className="term_modal_content">
							<ul className="table_content_list">
								{ContentList?.map((item, index) => (
									<li key={index}>
										<a
											href={`#${item?.title
												?.split(" ")
												?.join("-")
												?.toLowerCase()}`}
										>
											{item?.title}
										</a>
									</li>
								))}
							</ul>

							{AgreementContent?.map((item, index) => (
								<div
									className={`agreement-content-box pt-5`}
									id={`${item?.title?.split(" ")?.join("-")?.toLowerCase()}`}
									key={index}
								>
									<div className="title d-flex">
										<h6 className="serial-no me-3">{item?.serial_no}</h6>
										<h6 className="title">{item?.title}</h6>
									</div>
									<p className="agreement-para mb-0">{item?.description}</p>
								</div>
							))}
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default RegisterContributor;
