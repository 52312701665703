import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import AritstPageHeader from "../../components/AritstPageHeader";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/artist-release-form.css";
import { useAtistReleaseFormsQuery } from "../../store/services/ArtistCornerServices/ArtistBlogService";
import Pagination from "../../components/Pagination";
import Loader from "../../components/Loader";
import { backArrow } from "../../constant";
import Backhistory from "../../components/Backhistory";

// ArtistContestEventEndPoint
const ArtistReleaseForm = ({ setshow = false }) => {
	const [currentpage, setCurrentpage] = useState(1);
	const { data, isLoading } = useAtistReleaseFormsQuery(currentpage);
	const releaseForm = data?.data;
	const pages = Math.ceil(releaseForm?.total_size / releaseForm?.limit);
	const handlePageClick = async (data) => {
		setCurrentpage(data?.selected + 1);
	};

	const [loading, setLoading] = useState(false);
	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, 2000);
	}, []);
	const downloaderFromUrl = (Baseurl) => {
		let url = `https://admin-moonscape.developer-ourbase-camp.com/api/v3/contributor/artists-corner/release-forms-download?file_name=${Baseurl}`;
		window.location.href = url;
	};
	return isLoading ? (
		<Loader />
	) : (
		<>
			{setshow === false && <Header />}
			{/* Artist-header starts here */}
			{setshow === false && <AritstPageHeader />}
			{/* Artist-header starts here */}

			{/* release Sec Start Here */}
			<section className="release-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-9">
							<div className="back_gif_main d-flex justify-content-between align-items-center">
								<div className="release-heading-wrapper">
									<h2>Release Forms</h2>
								</div>
								{setshow === true && <Backhistory classNa={"back_icon"} />}
							</div>
							<div className="release-table-wrapper">
								<div className="table-responsive">
									<table className="table">
										<thead>
											<tr>
												<th className="title-heading">Title</th>
												<th className="action-heading">Actions</th>
											</tr>
										</thead>
										<tbody>
											{releaseForm?.relese_forms?.map((item, index) => {
												return (
													<tr key={index}>
														<td className="title-row">
															<h6 className="line-clamp-3">{item?.title}</h6>
														</td>
														<td className="actions-wrap">
															<div className="btn-wrap">
																<a href={item?.file_url} target="_blank">
																	View
																</a>
																<button
																	className="bg-transparent border-0"
																	onClick={() => {
																		downloaderFromUrl(item?.file);
																	}}
																>
																	Download
																</button>
															</div>
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="paginations-wrapper">
								<Pagination pages={pages} handlePageClick={handlePageClick} />
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* release Sec End Here */}
			{setshow === false && <Footer />}
		</>
	);
};

export default ArtistReleaseForm;
