import React from "react";
import { useProductsFilterQuery } from "../store/services/ContactServices";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
function HeaderFilter({
	filter,
	setFilter,
	hexa,
	picker,
	toggleChecked,
	saveColors2,
	saveColors,
	categoryFilter,
	setCategoryFilter,
	categoryTypes,
	setTypesFilter,
	Iamgetype,
	setIamgeType,
	categoryTrending,
	setTrendingFilter,
	HandleTagClick,
	ColorCode,
	setColorCode,
	search,
	setSearch,
	tagsData,
}) {
	const { data: allFilters, isLoading: filterload } = useProductsFilterQuery();
	const category_filters = allFilters?.categories;
	const imageType_filters = allFilters?.types;
	const Trending_filters = allFilters?.trending;
	const Tags_filter = allFilters?.tags;
	const Colors_Codes = allFilters?.colorcodes;
	const navigate = useNavigate();
	const [showColor, setShowColor] = useState(5);

	const setHandleCategoryFilter = (item) => {
		setCategoryFilter(item);
	};

	const HandleSearching = (e) => {
		e.stopPropagation();
		setSearch(false);
		navigate("/searching", {
			state: {
				package_type: categoryTypes,
				tag_ids: tagsData,
				trend_ids: categoryTrending,
				type: Iamgetype,
				category_id: categoryFilter,
				color_code: ColorCode?.split("#")?.[1],
				search: "",
			},
		});
	};

	return (
		<div className={filter ? "flter-box show" : "flter-box"}>
			<div className="topbar">
				<button
					className="btn"
					type="button"
					onClick={() => setFilter(!filter)}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="30"
						height="30"
						fill="currentColor"
						className="bi bi-x"
						viewBox="0 0 16 16"
					>
						<path
							d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
							fill="#fff"
						/>
					</svg>
				</button>
				<h4>Filter</h4>
				{/* button onClick={(e) => HandleSearching(e)} */}
				<div className="apply_filter_btn d-flex justify-content-end ms-auto pe-3">
					<button
						onClick={(e) => HandleSearching(e)}
						className="btn btn__filter"
					>
						Apply Filter
					</button>
				</div>
			</div>
			<div className="row">
				<div className="col-lg-6">
					<div className="radio-box">
						<div className="head">
							<h3>Categories</h3>
						</div>
						<ul className="list">
							{category_filters?.map((item, index) => {
								return (
									<li key={index}>
										<label
											className="filter_container form-check-label"
											htmlFor={`category${index}`}
										>
											<input
												id={`category${index}`}
												className="form-check-input"
												hidden
												defaultChecked={item?.id === categoryFilter}
												type="radio"
												name="category_radio"
												onClick={() => setHandleCategoryFilter(item?.id)}
											/>
											<span className="checkmark">
												<div className="dot"></div>
												<h4>{item?.name}</h4>
											</span>
										</label>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
				<div className="col-lg-6">
					<div className="radio-box images-box right-side">
						<div className="head">
							<h3>Image / Video Type</h3>
						</div>
						<ul className="list">
							{imageType_filters?.map((item, index) => {
								return (
									<li key={index}>
										<label className="filter_container form-check-label">
											<input
												className="form-check-input"
												hidden
												type="radio"
												name="image_radio"
												defaultChecked={item?.name === categoryTypes}
												onClick={() => setTypesFilter(item?.name)}
											/>
											<span className="checkmark">
												<div className="dot"></div>
												<h4>{item?.name}</h4>
											</span>
										</label>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
				<div className="col-lg-6">
					<div className="radio-box">
						<div className="head">
							<h3>Trending Now</h3>
						</div>
						<ul className="list">
							{Trending_filters?.map((item, index) => {
								return (
									<li key={index}>
										<label className="filter_container form-check-label">
											<input
												className="form-check-input"
												hidden
												type="radio"
												name="trending__radio"
												defaultChecked={item?.name === categoryTrending}
												onClick={() => setTrendingFilter(item?.name)}
											/>
											<span className="checkmark">
												<div className="dot"></div>
												<h4>{item?.name}</h4>
											</span>
										</label>
									</li>
								);
							})}
						</ul>
					</div>
				</div>

				<div className="col-lg-6">
					<div className="radio-box right-side">
						<div className="head">
							<h3>Select Type</h3>
						</div>
						<ul className="list">
							<li>
								<label
									className="filter_container form-check-label"
									onClick={() => setIamgeType("Image")}
								>
									<input
										className="form-check-input"
										hidden
										type="radio"
										name="image_radio"
										defaultChecked={Iamgetype === "Image"}
									/>
									<span className="checkmark">
										<div className="dot"></div>
										<h4>Image</h4>
									</span>
								</label>
							</li>
							<li>
								<label
									className="filter_container form-check-label"
									onClick={() => setIamgeType("Video")}
								>
									<input
										className="form-check-input"
										hidden
										type="radio"
										name="image_radio"
										defaultChecked={Iamgetype === "Video"}
									/>
									<span className="checkmark">
										<div className="dot"></div>
										<h4>Video</h4>
									</span>
								</label>
							</li>
						</ul>
					</div>
				</div>
				<div className="col-lg-6">
					<div className="radio-box colors">
						<div className="head">
							<h3>Color</h3>
							{Colors_Codes?.length > 5 && (
								<button
									className="clear"
									onClick={() => setShowColor(showColor + 5)}
								>
									Show More
								</button>
							)}
						</div>
						<ul className="list">
							{Colors_Codes?.map((item, index) => {
								return (
									index < showColor && (
										<li onClick={() => setColorCode(item)} key={index}>
											<label className="filter_container form-check-label">
												<input
													className="form-check-input"
													hidden
													type="radio"
													name="color_radio"
													defaultChecked={ColorCode === item}
												/>
												<span className="checkmark">
													<div
														className="dot red"
														style={{ backgroundColor: item }}
													></div>
												</span>
											</label>
										</li>
									)
								);
							})}
						</ul>
					</div>
				</div>
				{/* <div className="col-lg-6">
					<div className="radio-box tags right-side">
						<div className="head">
							<h3>Tags</h3>
						</div>
						<ul>
							{Tags_filter?.map((item, index) => {
								return (
									<li key={index}>
										<button onClick={(e) => HandleTagClick(e, item)}>
											<span className="name">{item?.tag}</span>
										</button>
									</li>
								);
							})}
						</ul>
					</div>
				</div> */}
			</div>
		</div>
	);
}

export default HeaderFilter;
