import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/thank-you.css";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { thankyouimg } from "../../constant";
import { useGetThanksPlaceApiQuery } from "../../store/services/ContactServices";
import currency from "currency-formatter";
import Loader from "../../components/Loader";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { emptyCart } from "../../store/reducers/CartrReducer";
import { saveAs } from "file-saver";
import { setUserToken } from "../../store/reducers/AuthReducer";

const Thankyou2 = () => {
	const href = window?.location.href;
	const { userToken } = useSelector((state) => state.AuthReducer);
	const { data, isLoading } = useGetThanksPlaceApiQuery(
		href?.split("?order_id=")?.[1],
	);
	const navigate = useNavigate();
	const ThankyouData = data?.data;
	const totalData = ThankyouData?.details?.map(
		(item) => item?.product?.package?.price,
	);
	let plusValue = totalData?.reduce(
		(accumulator, currentValue) => accumulator + currentValue,
		0,
	);
	const dispatch = useDispatch();
	useEffect(() => {
		return dispatch(emptyCart());
	}, []);

	const downloaderFromUrl = async (id) => {
		let url = `https://admin-moonscape.developer-ourbase-camp.com/api/v1/products/download-get-general?&id=${id}&token=${userToken}`;
		window.location.href = url;
	};

	const ImageDownloader = (imageSrc, image) => {
		saveAs(imageSrc, image);
	};

	return isLoading ? (
		<Loader />
	) : (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}

			{/* Thank You Start Here */}
			<section className="thank_you">
				<div className="container">
					<div className="heading_wrapper">
						<h2>THANK YOU FOR PURCHASING</h2>
						<h4>YOUR DOWNLOAD LINK IS READY</h4>

						<p className="note">
							<i>Check your mail for the paid receipt</i>
						</p>
						<div className="back-button">
							<Link to="/">Go back to homepage</Link>
						</div>
					</div>

					{/* thank you 2 details starts here */}
					<div className="row">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<div className="tahnk-wrapp">
								<div className="pro-headin-wrapper">
									<h5>PRODUCT DETAILS</h5>
								</div>
								<div className="beachs-cards-wrapper">
									{ThankyouData?.details?.map((item, index) => {
										return (
											<div className="beach-card-wrapper" key={index}>
												<div className="beach-img-wrapper">
													<figure>
														<img
															src={item?.product?.image_url}
															alt="Product Image"
														/>
													</figure>
												</div>
												<div className="bech-content-wrapper">
													<h5>{item?.product?.name}</h5>
													<h4>
														{currency.format(item?.product?.package?.price, {
															code: "USD",
														})}{" "}
														{item?.product?.package?.name}
													</h4>
													{/* <h6>{item?.product?.package?.details}</h6> */}
													<h6>{item?.product?.file_resolution}</h6>
													<button
														className="btn d-flex gap-2 align-items-center"
														onClick={() => {
															downloaderFromUrl(item?.product?.id);
														}}
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="17.031"
															height="17.031"
															viewBox="0 0 17.031 17.031"
														>
															<g
																id="Icon_feather-download"
																data-name="Icon feather-download"
																transform="translate(1 1)"
															>
																<path
																	id="Path_8715"
																	data-name="Path 8715"
																	d="M19.531,22.5v3.34a1.67,1.67,0,0,1-1.67,1.67H6.17A1.67,1.67,0,0,1,4.5,25.84V22.5"
																	transform="translate(-4.5 -12.479)"
																	fill="none"
																	stroke="#fff"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	strokeWidth="3"
																/>
																<path
																	id="Path_8716"
																	data-name="Path 8716"
																	d="M10.5,15l4.175,4.175L18.851,15"
																	transform="translate(-7.16 -9.155)"
																	fill="none"
																	stroke="#fff"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	strokeWidth="3"
																/>
																<path
																	id="Path_8717"
																	data-name="Path 8717"
																	d="M18,14.521V4.5"
																	transform="translate(-10.484 -4.5)"
																	fill="none"
																	stroke="#fff"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	strokeWidth="3"
																/>
															</g>
														</svg>

														<span>Download</span>
													</button>
												</div>
											</div>
										);
									})}
								</div>

								<div className="row">
									<div className="col-lg-6"></div>
									<div className="col-lg-6">
										<div className="product-detail">
											<div className="item-total">
												<h4>
													<span className="property">Item Total :</span>
													<span className="value">
														{currency.format(plusValue, {
															code: "USD",
														})}
													</span>
												</h4>
											</div>
											<div className="order-total">
												<h4>
													<span className="property">Order Total</span>
													<span className="value">
														{currency.format(plusValue, {
															code: "USD",
														})}
													</span>
												</h4>
											</div>
										</div>
										<div className="button-group">
											<a className="btn" href="/download-history">
												View Purchased Item
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-2"></div>
					</div>
					{/* thank you 2 details ends here */}
				</div>
			</section>
			{/* Thank You End Here */}

			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default Thankyou2;
