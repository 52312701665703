import React, { useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../assets/css/testimonials.css";
import { TestiImg1, TestiImg2, TestiImg3, backArrow } from "../../constant";
import GoTop from "../../components/GoTop";
import { useTestimonialsQuery } from "../../store/services/ContactServices";
import Loader from "../../components/Loader";
import Backhistory from "../../components/Backhistory";

const Testimonials = () => {
	const { data, isLoading } = useTestimonialsQuery();
	const testimonial = data?.data;
	const [count, setCount] = useState(5);

	return isLoading ? (
		<Loader />
	) : (
		<>
			<GoTop />
			{/* Header Start Here */}
			<Header />
			{/* Header Start Here */}

			{/* Testimonial Sec Start Here */}
			<section className="testimonials">
				<div className="container">
					<div className="back_gif_main d-flex justify-content-between align-items-center">
						<div className="testimonials-wrapper">
							<h1>Testimonials</h1>
							{/* <p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor
						</p> */}
						</div>
						<Backhistory classNa={"back_icon"} />
					</div>
					<div className="all-testimonials">
						{testimonial?.map((data, index) => {
							console.log(data, "pdsoap3");
							return (
								index < count && (
									<div className="testimonial-box" key={index}>
										<div className="name-box">
											<div className="img-box">
												<figure>
													<img
														src={data?.image_url}
														alt="user-image"
														className="img-fluid"
														width={"100%"}
													/>
												</figure>
											</div>
											<div className="txt">
												<h4 style={{ fontSize: "14px" }}>{data?.title}</h4>
											</div>
										</div>
										<div className="para">
											<p
												dangerouslySetInnerHTML={{ __html: data?.description }}
												className="limited-text"
											></p>
										</div>
									</div>
								)
							);
						})}
						{testimonial?.length > count && (
							<button
								onClick={() => setCount(count + 5)}
								className="btn"
								style={{
									display: "flex",
									margin: "0 auto",
								}}
							>
								Load more
							</button>
						)}
					</div>
				</div>
			</section>
			{/* Testimonial Sec End Here */}

			{/* Footer Start Here */}
			<Footer />
			{/* Footer Start Here */}
		</>
	);
};

export default Testimonials;
