import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
	ArtistCategory,
	ArtistCheckList,
	ArtistCheckStore,
	ArtistEarning,
	ArtistEarningReports,
	ArtistEdit,
	ArtistInfo,
	ArtistMotionTips,
	ArtistPaypalList,
	ArtistPaypalStore,
	ArtistTags,
	ArtistTips,
	ArtistUpdateProfile,
	ArtistUpload,
	ArtistUploadWorks,
	ArtistUploadWorksDelete,
	BaseUrlTwo,
	Deleteremoval,
	RemovalPoint,
	contribStatus,
	contributorForget,
	contributorLogins,
	contributorRegisters,
	contributorResetPassword,
} from "../../../utils/Urls";

const AuthContributorServices = createApi({
	reducerPath: "AuthContributorServices",
	tagTypes: "editInfos",
	baseQuery: fetchBaseQuery({
		baseUrl: BaseUrlTwo,
		prepareHeaders: (headers, { getState }) => {
			const reducers = getState();
			const token = reducers?.AuthReducer?.contrubutorToken;
			headers.set("authorization", token ? `Bearer ${token}` : "");
			headers.set("Access-Control-Allow-Origin", "*");
			return headers;
		},
	}),
	endpoints: (builder) => {
		return {
			contributorRegister: builder.mutation({
				query: (registerData) => {
					return {
						url: contributorRegisters,
						method: "POST",
						body: registerData,
					};
				},
			}),
			contributorLogin: builder.mutation({
				query: (loginData) => {
					return {
						url: contributorLogins,
						method: "POST",
						body: loginData,
					};
				},
			}),
			contributorForget: builder.mutation({
				query: (forgetData) => {
					return {
						url: contributorForget,
						method: "POST",
						body: forgetData,
					};
				},
			}),
			contributorNewPassword: builder.mutation({
				query: (newPasswordData) => {
					return {
						url: contributorResetPassword,
						method: "POST",
						body: newPasswordData,
					};
				},
			}),
			contributorusermeInfo: builder.query({
				query: () => {
					return {
						url: ArtistInfo,
						method: "GET",
					};
				},
				providesTags: ["editInfo"],
			}),
			usereditInfo: builder.mutation({
				query: (newUserData) => {
					return {
						url: ArtistUpdateProfile,
						method: "POST",
						body: newUserData,
					};
				},
				invalidatesTags: ["editInfo"],
			}),
			// usereditProfile: builder.mutation({
			// 	query: (newUserData) => {
			// 		return {
			// 			url: "/customer/update-profile-pic",
			// 			method: "POST",
			// 			body: newUserData,
			// 		};
			// 	},
			// 	invalidatesTags: ["editInfo"],
			// }),
			contributoreditPassword: builder.mutation({
				query: (newUserData) => {
					return {
						url: ArtistEdit,
						method: "POST",
						body: newUserData,
					};
				},
			}),
			contributorUpload: builder.mutation({
				query: (newUserData) => {
					return {
						url: ArtistUpload,
						method: "POST",
						body: newUserData,
					};
				},
			}),
			contributorCheckstore: builder.mutation({
				query: (newUserData) => {
					return {
						url: ArtistCheckStore,
						method: "POST",
						body: newUserData,
					};
				},
			}),
			contributorPaypalstore: builder.mutation({
				query: (newUserData) => {
					return {
						url: ArtistPaypalStore,
						method: "POST",
						body: newUserData,
					};
				},
			}),
			contributorCategorys: builder.query({
				query: () => {
					return {
						url: ArtistCategory,
						method: "GET",
					};
				},
			}),
			contributorTags: builder.query({
				query: () => {
					return {
						url: ArtistTags,
						method: "GET",
					};
				},
			}),
			contributorTipe: builder.query({
				query: () => {
					return {
						url: ArtistTips,
						method: "GET",
					};
				},
			}),
			contributorChecklist: builder.query({
				query: () => {
					return {
						url: ArtistCheckList,
						method: "GET",
					};
				},
			}),
			contributorPaypallist: builder.query({
				query: () => {
					return {
						url: ArtistPaypalList,
						method: "GET",
					};
				},
			}),
			contributorStatuss: builder.query({
				query: (slug) => {
					return {
						url: `${contribStatus}?${slug}`,
						method: "GET",
					};
				},
			}),
			contributorEarning: builder.query({
				query: () => {
					return {
						url: ArtistEarning,
						method: "GET",
					};
				},
			}),
			contributorEarningReport: builder.query({
				query: () => {
					return {
						url: ArtistEarningReports,
						method: "GET",
					};
				},
			}),
			contributorUploadWork: builder.query({
				query: (data) => {
					return {
						url: `${ArtistUploadWorks}?search=${data?.search}&status=${data?.status}`,
						method: "GET",
					};
				},
			}),
			contributorUploadWorkDelete: builder.mutation({
				query: (id) => {
					return {
						url: `${ArtistUploadWorksDelete}/${id}`,
						method: "DELETE",
					};
				},
			}),
			contributorRemovalProduct: builder.mutation({
				query: (data) => {
					return {
						url: `${Deleteremoval}`,
						method: "POST",
						body: data,
					};
				},
			}),
			contributorMotionTipe: builder.query({
				query: (typeId) => {
					return {
						url: `${ArtistMotionTips}?type_name=${
							typeId === undefined ? "" : typeId
						}`,
						method: "GET",
					};
				},
			}),
			contributorRemoval: builder.query({
				query: () => {
					return {
						url: `${RemovalPoint}`,
						method: "GET",
					};
				},
			}),
			// userresetPassword: builder.mutation({
			// 	query: (newData) => {
			// 		return {
			// 			url: resetPassword,
			// 			method: "POST",
			// 			body: newData,
			// 		};
			// 	},
			// }),
		};
	},
});

export const {
	useContributorRegisterMutation,
	useContributorLoginMutation,
	useContributorForgetMutation,
	useContributorNewPasswordMutation,
	useContributorusermeInfoQuery,
	useContributorTagsQuery,
	useContributorEarningQuery,
	useContributorTipeQuery,
	useContributorStatussQuery,
	useContributorUploadWorkQuery,
	useContributorEarningReportQuery,
	useContributorMotionTipeQuery,
	useContributorCategorysQuery,
	useContributorChecklistQuery,
	useContributorPaypallistQuery,
	useContributorRemovalQuery,
	useUsereditInfoMutation,
	useContributoreditPasswordMutation,
	useContributorCheckstoreMutation,
	useContributorPaypalstoreMutation,
	useContributorUploadMutation,
	useContributorUploadWorkDeleteMutation,
	useContributorRemovalProductMutation,
	// useUserresetPasswordMutation,
} = AuthContributorServices;
export default AuthContributorServices;
